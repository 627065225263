import React, { createContext, useState, useEffect } from 'react';
import { getCookie } from '../utils/cookieHelper';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    const cookieValue = getCookie('dewcare_user');
    if (cookieValue) {
      try {
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/${cookieValue.userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        // Ensure the profile picture always comes from the API
        const profilePictureUrl = `https://dewcare.co.uk/storage/app/public/docs/${data.data.passport}`;
        
        setUserData({
          ...data,
          data: { ...data.data, passport: profilePictureUrl },
        });
        
        // Cache profile picture locally for optimization
        localStorage.setItem('profilePicture', profilePictureUrl);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  

  const updateProfilePicture = (newPictureUrl) => {
    setUserData((prevData) => {
      const updatedData = {
        ...prevData,
        data: { ...prevData?.data, passport: newPictureUrl },
      };
      localStorage.setItem('profilePicture', newPictureUrl); // Persist the updated profile picture
      return updatedData;
    });
  };

  useEffect(() => {
    fetchUserData();

    // Load the profile picture from localStorage if available
    const storedPicture = localStorage.getItem('profilePicture');
    if (storedPicture) {
      setUserData((prevData) => ({
        ...prevData,
        data: { ...prevData?.data, passport: storedPicture },
      }));
    }
  }, []);

  return (
    <UserContext.Provider value={{ userData, loading, setUserData, updateProfilePicture, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};