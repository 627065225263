import React, { useContext, useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar, Typography, Box, Modal, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { UserContext } from '../components/userContext';
import { deleteCookie } from '../utils/cookieHelper';

const drawerWidth = 300;

function DrawerMenu({ open, onClose, currentPath }) {
  const { userData, loading } = useContext(UserContext); // Access userData from the context
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState(null); // For caching avatar

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/home' },
    { text: 'Profile', icon: <PersonIcon />, path: '/profile' },
    { text: 'Availability Schedule', icon: <ScheduleIcon />, path: '/availability-schedule' },
    { text: 'New Time-sheet', icon: <AssignmentIcon />, path: '/client-selection', requiresPosition: true },
    { text: 'Time-sheet History', icon: <HistoryIcon />, path: '/history' },
    { text: 'Employee Contract', icon: <DescriptionIcon />, path: '/contract' },
  ];

  useEffect(() => {
    // Load cached profile picture from localStorage
    const cachedProfilePicture = localStorage.getItem('profilePicture');
    if (cachedProfilePicture) {
      setAvatarSrc(cachedProfilePicture);
    } else if (userData?.data?.passport) {
      setAvatarSrc(`https://dewcare.co.uk/storage/app/public/docs/${userData.data.passport}`);
    }
  }, [userData]);

  const handleLogout = () => {
    deleteCookie('dewcare_user'); // Remove the user cookie
    navigate('/'); // Redirect to the login page
  };

  const handleMenuClick = (item) => {
    if (item.requiresPosition && !userData?.data?.position) {
      setModalOpen(true); // Show modal if position is missing
    } else {
      navigate(item.path);
      onClose();
    }
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : userData ? (
            <>
              <Avatar
                sx={{ width: 56, height: 56 }}
                src={avatarSrc}
              >
                {!avatarSrc && <AccountCircleIcon />} {/* Show default icon if no avatar */}
              </Avatar>
              <Typography variant="h6" sx={{ mt: 1 }}>
                {userData?.data?.first_name} {userData?.data?.last_name}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">No employee data</Typography>
          )}
        </Box>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleMenuClick(item)}
              sx={{
                backgroundColor: currentPath === item.path ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                '&:hover': {
                  backgroundColor: currentPath === item.path ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      {/* Modal for missing position */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 3,
            boxShadow: 24,
            outline: 'none',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Position Not Specified
          </Typography>
          <Typography variant="body2" gutterBottom>
            Time-sheet cannot be created without an employee position. Kindly contact admin.
          </Typography>
          <Button
            onClick={() => setModalOpen(false)}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            fullWidth
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default DrawerMenu;
