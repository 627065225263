import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../components/userContext';
import './index.scss';
import { Container, Avatar, IconButton, Box, CircularProgress, Button } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { toast } from 'react-toastify';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';

const Profile = () => {
  usePageBackgroundColor('#E1E2E4');

  const { userData, loading: userLoading, setUserData } = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  // Load cached profile picture from localStorage on component mount
  useEffect(() => {
    const cachedProfilePicture = localStorage.getItem('profilePicture');
    if (cachedProfilePicture) {
      setAvatarSrc(cachedProfilePicture);
    } else if (userData?.data?.passport) {
      setAvatarSrc(`https://dewcare.co.uk/storage/app/public/docs/${userData.data.passport}`);
    }
  }, [userData]);

  const handleChangePassword = async () => {
    // ... password change logic
  };

  const getDisplayValue = (value) => (value ? value : <em>Not specified</em>);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error('Please select an image to upload.');
      return;
    }

    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append('employee_id', userData?.data?.id);
    formData.append('passport', selectedFile);
    formData.append('type', 'mobile');

    try {
      const response = await fetch('https://dewcare.co.uk/mobile/employee/photo', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (response.ok && data?.success) {
        toast.success('Profile picture changed successfully!');
        const updatedImageUrl = `https://dewcare.co.uk/storage/app/public/docs/${data.passport || data.updated_passport_url}`;

        // Convert image to base64 and update immediately
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result;
          localStorage.setItem('profilePicture', base64Image);
          setAvatarSrc(base64Image);
          setUserData({
            ...userData,
            data: { ...userData.data, passport: updatedImageUrl },
          });
        };
        reader.readAsDataURL(selectedFile);

        setSelectedFile(null); // Hide upload button
      } else {
        toast.error(data.message || 'Image upload failed.');
      }
    } catch (error) {
      toast.error('An error occurred during image upload.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container>
      <div className="profile">
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <Box position="relative" display="inline-block">
            <Avatar
              sx={{
                width: 100,
                height: 100,
                border: '3px solid white',
                cursor: 'pointer',
              }}
              src={
                selectedFile
                  ? URL.createObjectURL(selectedFile)
                  : avatarSrc
              }
            >
              {!avatarSrc && !selectedFile && (
                <AccountCircleIcon fontSize="large" />
              )}
            </Avatar>

            <IconButton
              component="label"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: 0.5,
              }}
            >
              <EditIcon fontSize="small" />
              <input type="file" hidden onChange={handleFileChange} />
            </IconButton>
          </Box>

          {selectedFile && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={loading} // Disable button during loading
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Click here to update'}
            </Button>
          )}
        </Box>
        <h1 className="header">Personal Information</h1>
        <div className="detailContainer">
          <div className="detail">
            <p className="object">Name:</p>
            <p className="desc">
              {loading ? 'Loading...' : (
                <>
                  {getDisplayValue(userData?.data?.first_name)} {getDisplayValue(userData?.data?.last_name)}
                </>
              )}
            </p>
          </div>
          <div className="detail">
            <p className="object">Address:</p>
            <p className="desc">{loading ? 'Loading...' : getDisplayValue(userData?.data?.address)}</p>
          </div>
          <div className="detail">
            <p className="object">Email Address:</p>
            <p className="desc">{loading ? 'Loading...' : getDisplayValue(userData?.data?.email)}</p>
          </div>
          <div className="detail">
            <p className="object">Phone Number:</p>
            <p className="desc">{loading ? 'Loading...' : getDisplayValue(userData?.data?.phone)}</p>
          </div>
          <div className="detail">
            <p className="object">Job Role:</p>
            <p className="desc">{loading ? 'Loading...' : getDisplayValue(userData?.data?.position)}</p>
          </div>
        </div>

        <h1 className="header" style={{ marginTop: '30px' }}>Change Password</h1>
        <div className="passwordCon">
          <div style={{ marginTop: '20px' }}>
            <input 
              type="password" 
              placeholder="Current password" 
              className="input"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <input 
              type="password" 
              placeholder="New password" 
              className="input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input 
              type="password" 
              placeholder="Repeat new password" 
              className="input"
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
            />
          </div>

          <div className="submit">
            <button 
              className="btn" 
              onClick={handleChangePassword}
              disabled={!currentPassword || !newPassword || !repeatNewPassword}
            >
              Save Password
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;